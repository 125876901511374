import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

import { ISOLocaleString } from '../../helpers';
import grafanaUrl from '../../lib/util';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  expandroot: {
    width: '100%',
    height: '1000vh',
    backgroundColor: '#6d6d6db3',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const LogTable = props => {
  const { className, logs, ...rest } = props;
  const classes = useStyles();

  const highlight = Number(window.location.hash.substr(3));

  const lineLinkFn = (n) => () => {
    window.location.hash = '#ln' + n;
  };

  const renderLog = (log, n) => {
    var ts = '-';
    if (log.ts !== 0) {
      var d = new Date(0);
      d.setUTCSeconds(log.ts);
      ts = ISOLocaleString(d);
    }

    return (
      <tr className={ n === highlight ? 'logHi' : ''} id={'ln' + (n+1)}>
        <td className="logN" onClick={lineLinkFn(n)}>{n}</td>
        <td className="logTS">{ts}</td>
        <td className="logM">{log.m}</td>
      </tr>
    );
  }

  return (
    <Grid container disableGutters direction="column" alignItems="center" spacing={2}>
      <Grid item xs={12}>
      <div className={classes.root}>
        <table className="uploadTable logTable">
          {logs.map(renderLog)}
        </table>
      </div>
      </Grid>
    </Grid>
  );
}

export default LogTable
