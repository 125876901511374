let tzName;

const pad = n => n < 10 ? '0'+n : n;

export const GetTzShort = () => {
  if (!tzName) {
    tzName = new Date()
      .toLocaleDateString(0, {day:'2-digit',timeZoneName: 'short'})
      .substring(4);
  }
  return tzName;
}

export const ISOLocaleString = d => {
  d = new Date(d);
  return d.getFullYear()+'-'
       + pad(d.getMonth()+1)+'-'
       + pad(d.getDate())+' '
       + pad(d.getHours())+':'
       + pad(d.getMinutes())+':'
       + pad(d.getSeconds())+' '
       + GetTzShort();
}
