import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import UploadsTable from './UploadsTable';
import mockData from './data';
import api from '../../lib/api';

const useStyles = makeStyles(theme => ({
  root: {
    width: '95vw',
    maxWidth: '1600px',
    margin: '0 auto',
    padding: theme.spacing(1)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Uploads = () => {
  const classes = useStyles();
  const parts = window.location.pathname.split('/');
  const envName = parts[parts.length-1];

  const [uploads, setUploads] = useState(false);
  const [err, setErr] = useState(false);

  const getUploads = (envName, cur, setFn, setErr) => {
    if (cur !== false) {
      return;
    }
    api.get('/api/uploads/' + envName)
      .then(function (response) {
        if (response.status === 401 || response.status === 403) {
          localStorage.removeItem("token");
          window.location = '/login';
          setErr(<Redirect to="/login" />);
          return
        }
        setFn(response.data);
      })
      .catch(function (error) {
        setErr("error");
    })
  }

  getUploads(envName, uploads, setUploads, setErr);

  if (err !== false) {
    return (
      <div className="loadingBox">
        <div className="loading">
        {err}
        </div>
      </div>
    );
  }

  if (uploads === false) {
    return (
      <div className="loadingBox">
        <div className="loading">
          <CircularProgress color="secondary" />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <UploadsTable uploads={uploads} />
      </div>
    </div>
  );
};

export default Uploads;
