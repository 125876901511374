import React from 'react';
import axios from "axios";

function api() {
  let apiConfig = {
    baseURL: "https://orion.uttoapp.com",
    headers: {
      "Content-Type": "application/json",
    }
  };

  let apiInstance = axios.create(apiConfig);

  apiInstance.interceptors.request.use(config => {
    if (
      !config.headers["Authorization"] &&
      localStorage.getItem("token") !== null
    ) {
      config.headers["Authorization"] = localStorage.getItem("token");
    }
    if (
      config.headers["Authorization"] &&
      localStorage.getItem("token") === null
    ) {
      delete config.headers["Authorization"];
    }

    return config;
  });

  apiInstance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    console.log(error);
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("token");
      if (error.config.url !== "/login") {
        window.location = "/login";
      }
    } else {
      return Promise.reject(error);
    }
  });

  return apiInstance;
}

export default api();
