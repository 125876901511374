import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Minimal as MinimalLayout } from './layouts';

import {
  Login as LoginView,
  Uploads as UploadsView,
  NotFound as NotFoundView,
  AppLogs as AppLogsView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/prod" />
      <RouteWithLayout
        component={AppLogsView}
        exact
        layout={MinimalLayout}
        path="/applogs/dev"
      />
      <RouteWithLayout
        component={AppLogsView}
        exact
        layout={MinimalLayout}
        path="/applogs/prod"
      />
      <RouteWithLayout
        component={UploadsView}
        exact
        layout={MinimalLayout}
        path="/dev"
      />
      <RouteWithLayout
        component={UploadsView}
        exact
        layout={MinimalLayout}
        path="/prod"
      />
      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
