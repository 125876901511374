import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import LogTable from './LogTable';
import api from '../../lib/api';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AppLogs = () => {
  const classes = useStyles();
  const parts = window.location.pathname.split('/');
  const envName = parts[parts.length-1];

  const qParams = new URLSearchParams(window.location.search);
  const applogID = qParams.get('id');

  const [logs, setLogs] = useState(false);
  const [err, setErr] = useState(false);

  const getLogs = (envName, id, cur, setFn, setErr) => {
    if (cur !== false) {
      return;
    }
    api.get('/api/applogs/' + envName + '/' + id)
      .then(function (response) {
        if (response.status === 401 || response.status === 403) {
          localStorage.removeItem("token");
          window.location = '/login';
          setErr(<Redirect to="/login" />);
          return
        }
        setFn(response.data);
      })
      .catch(function (error) {
        setErr("error");
    })
  }

  getLogs(envName, applogID, logs, setLogs, setErr);

  if (err !== false) {
    return (
      <div className="loadingBox">
        <div className="loading">
        {err}
        </div>
      </div>
    );
  }

  if (logs === false) {
    return (
      <div className="loadingBox">
        <div className="loading">
          <CircularProgress color="secondary" />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <LogTable logs={logs} />
      </div>
    </div>
  );
};

export default AppLogs;
