import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2),
    width: "100%",
    maxWidth: "700px",
    margin: "auto",
  }
}));

const Login = () => {
  const classes = useStyles();
  const parts = window.location.pathname.split('/');
  const envName = parts[parts.length-1];

  const [token, setToken] = useState("");
  const [done, setDone] = useState(false);

  const submit = (token) => {
    localStorage.setItem("token", token)
    setDone(true);
  }

  if (done) {
    return <Redirect to="/dev" />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div class="loginBox">
        <h3>Login</h3>
        <TextField onChange={(ev) => setToken(ev.target.value)} hintText="token" />
        <Button color="secondary" variant="contained" onClick={() => submit(token)} fullWidth={true}>submit</Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
