import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';

const envNames = ['prod', 'dev'];

const curEnv = () => {
  const parts = window.location.pathname.split('/');
  return parts[parts.length-1];
}

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const link = (envName) => () => { window.location = '/' + envName; }

const renderEnv = (name) => {
  var classes = "envBtn";
  if (name === curEnv()) {
    classes += " envActive";
  }
  return (
    <div className={classes} onClick={link(name)}>
      <div className="envLabel">{name}</div>
    </div>
  );
};

const Topbar = props => {
  const { className, envName, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            className="logo"
            alt="orion"
            src="/images/logos/logo--white.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <div className="envSelect">
          {envNames.map(renderEnv)}
        </div>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  envName: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
