

function grafanaUrl(id, start, end) {
  if (start === 0 || end === 0) {
    return ""
  }

  const parts = window.location.pathname.split('/');
  const envName = parts[parts.length-1];

  var base = "";
  if (envName === "dev") {
    base = "http://orion.uttoapp.com:3000/d/diagnostics/diagnostics?orgId=1"
    //base = "https://graphs.dev.test.utto.io/d/diagnostics/diagnostics?orgId=1"
  } else if (envName === "prod") {
    base = "https://graphs.cloud.utto.io/d/diagnostics2/diagnostics2?orgId=1"
  } else {
    return ""
  }

  return base + "&from=" + (end - 30) + "&to=" + (start + 30) + "&var-upload_id=" + id
}

export default grafanaUrl;
